














import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchItemCreate } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchVerifyEmail, dispatchGetUserProfile } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { commitAddNotification } from '@/store/main/mutations';


@Component
export default class Dashboard extends Vue {
  public mounted() {
    this.checkToken();
  }

  public checkToken() {
    const token = (this.$router.currentRoute.query.token as string);
    if (!token) {
      commitAddNotification(this.$store, {
        content: '連結使用錯誤',
        color: 'error',
      });
      this.$router.push('/login');
    } else {
      // return token;
      this.submit(token);
    }
  }

  public async submit(token) {
    // if (await this.$validator.validateAll()) {
    // if (token) {
    console.log(token);
    await dispatchVerifyEmail(this.$store, { token: token });
    this.$router.push('/login');
    // }
    // }
  }
}

